import { RouteObject } from 'react-router-dom';

import { GuestUserAppContainer } from '@pages/app-container';
import { PublicUserAppContainer } from '@pages/app-container/public-user-app-container';

export const GuestUserRoutes: RouteObject = {
  path: '/',
  children: [
    {
      element: <GuestUserAppContainer />,
      children: [
        { path: 'login', lazy: () => import('@pages/login') },
        { path: 'register', lazy: () => import('@pages/register') },
        { path: 'forgot-password', lazy: () => import('@pages/forgot-password') },
        { path: 'reset-password', lazy: () => import('@pages/reset-password') },
        { path: 'unsubscribe', lazy: () => import('@pages/unsubscribe') }
      ]
    },
    {
      element: <PublicUserAppContainer />,
      path: '/guest',
      children: [
        { path: 'experts', lazy: () => import('@pages/experts') },
        { path: 'ambassadors', lazy: () => import('@pages/ambassadors') }
      ]
    }
  ]
};
