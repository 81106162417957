import { Outlet } from 'react-router-dom';
import MUIAppBar from '@mui/material/AppBar';

import { ENVIRONMENTS } from '@config/environments';
import { Box, Button, ButtonLink, Container, ContainerProps, Grid, Stack, ThemeProvider, Toolbar, useMediaQuery, useTheme } from '@tulp';

import './guest-user-app-container.css';
import logo from '../../images/app_bar_logo.png';

export type PublicUserPageContainerPropsType = {
  containerMaxWidth?: ContainerProps['maxWidth'];
  needsLogin?: boolean;
};

const LoginURL = `${ENVIRONMENTS.URL}login`;
const RegisterURL = `${ENVIRONMENTS.URL}register`;
const HomeURL = `${ENVIRONMENTS.URL}home`;

export function PublicUserAppContainer(props: Readonly<PublicUserPageContainerPropsType>) {
  const theme = useTheme();
  const downOfMd = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  return (
    <Grid container component='main' className='guest-page'>
      <ThemeProvider>
        <MUIAppBar variant='outlined' elevation={0} position='fixed' color='inherit' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar sx={{ mx: 6 }}>
            <Box display='flex' alignItems='center' flexGrow={1} component='a' href={props.needsLogin ? LoginURL : HomeURL}>
              <img style={{ width: downOfMd ? 150 : 200 }} src={logo} alt='SalesHookup logo' />
            </Box>
            {props.needsLogin ? (
              <Stack spacing={2} direction='row'>
                <Button href={LoginURL} size='small' variant='outlined'>
                  Log in
                </Button>
                <Button href={RegisterURL} size='small' variant='outlined'>
                  Sign up
                </Button>
              </Stack>
            ) : (
              <ButtonLink to={HomeURL} size='small' variant='outlined'>
                Go to Home
              </ButtonLink>
            )}
          </Toolbar>
        </MUIAppBar>
        <Toolbar />
        <Container maxWidth={props.containerMaxWidth ?? 'lg'} sx={{ mt: 15, pb: 4 }}>
          <Outlet />
        </Container>
      </ThemeProvider>
    </Grid>
  );
}
