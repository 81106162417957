import { GroupHookupConnectionItemListType, GroupHookupItemListType, GroupHookupStatusType } from '../../query/types';

export const GroupHookupConnectionsMockData: GroupHookupConnectionItemListType[] = Array.from({ length: 10 }, (_, i) => ({
  userUuid: `userUuid${i}`,
  id: `userId${i}`,
  fullName: `Full Name ${i}`,
  username: `username${i}`,
  jobPosition: `Job Position ${i}`,
  companyName: `Company Name ${i}`,
  imageUrl: `http://example.com/image${i}.jpg`,
  linkedinUrl: `http://linkedin.com/in/username${i}`,
  groupHookupName: `Group Hookup Name ${i}`,
  groupHookupDate: new Date().toISOString(),
  startDateTime: new Date().toISOString(),
  timeCycleName: `Time Cycle ${i}`
}));

export const GroupHookupsMockData: (GroupHookupItemListType & { page: number })[] = Array.from({ length: 10 }, (_, i) => ({
  uuid: `uuid${i}`,
  hostFullName: `Host Full Name ${i}`,
  title: `Title ${i}`,
  category: `Category ${i}`,
  startDate: new Date().toISOString(),
  status: GroupHookupStatusType.PENDING,
  duration: i,
  slotsLeft: 10 - i,
  hostImageUrl: `http://example.com/image${i}.jpg`,
  description: i % 2 === 0 ? `Description ${i}` : null,
  highlighted: i % 2 === 0,
  participants: Array.from({ length: i }, (_, j) => `Participant ${j}`),
  page: i,
  sponsored: false,
  hostRoles: ['user']
}));

export const GroupHookupsListMockData = [
  {
    uuid: 'cd54e8ff-24dc-4013-8095-39a8767e867f',
    hostFullName: 'Sales Hookup',
    hostUUID: 'bc7b8589-10a9-438d-ac4e-08afcd9744b7',
    title: 'Elevator pitch practice ',
    category: 'PRACTICE',
    startDate: '2024-05-28T16:00:00',
    status: 'APPROVED',
    duration: 15,
    slotsLeft: 7,
    hostImageUrl: 'https://s3.eu-west-3.amazonaws.com/saleshookup-prod/660acd3e67263b02fc8f3797/avatar/Favicon_Saleshookup.png',
    description: '*This group hookup does not have a host.**\n\nEach member practices their 1-minute elevator pitch and the other members give feedback.',
    roomResponse: {
      webhook: null,
      autoCloseConfig: {
        duration: 15,
        type: 'session-ends'
      },
      disabled: false,
      createdAt: '2024-05-14T08:25:43.264Z',
      updatedAt: '2024-05-14T08:25:43.264Z',
      roomId: '3svm-qiyt-1g6u',
      customRoomId: 'Elevator pitch practice ',
      links: {
        get_room: 'https://api.videosdk.live/v2/rooms/3svm-qiyt-1g6u',
        get_session: 'https://api.videosdk.live/v2/sessions/'
      },
      id: '66432007a42df057c0712627'
    },
    participants: ['bc7b8589-10a9-438d-ac4e-08afcd9744b7'],
    cancellationReason: null,
    highlighted: false
  },
  {
    uuid: 'acd54d47-ae15-4b96-af03-4a5aa073b7d6',
    hostFullName: 'Sales Hookup',
    hostUUID: 'bc7b8589-10a9-438d-ac4e-08afcd9744b7',
    title: 'Elevator pitch practice',
    category: 'PRACTICE',
    startDate: '2024-05-21T18:00:00',
    status: 'APPROVED',
    duration: 15,
    slotsLeft: 7,
    hostImageUrl: 'https://s3.eu-west-3.amazonaws.com/saleshookup-prod/660acd3e67263b02fc8f3797/avatar/Favicon_Saleshookup.png',
    description: '*This group hookup does not have a host.**\n\nEach member does their 1-minute elevator pitch and the other members give feedback.',
    roomResponse: {
      webhook: null,
      autoCloseConfig: {
        duration: 15,
        type: 'session-ends'
      },
      disabled: false,
      createdAt: '2024-05-14T08:24:53.766Z',
      updatedAt: '2024-05-14T08:24:53.766Z',
      roomId: 'fz5w-ud96-nhh2',
      customRoomId: 'Elevator pitch practice',
      links: {
        get_room: 'https://api.videosdk.live/v2/rooms/fz5w-ud96-nhh2',
        get_session: 'https://api.videosdk.live/v2/sessions/'
      },
      id: '66431fd5a42df057c0711b1e'
    },
    participants: ['bc7b8589-10a9-438d-ac4e-08afcd9744b7'],
    cancellationReason: null,
    highlighted: false
  },
  {
    uuid: '34983e7d-9e38-4d4d-bab4-0d07a76a04d0',
    hostFullName: 'Sales Hookup',
    hostUUID: 'bc7b8589-10a9-438d-ac4e-08afcd9744b7',
    title: 'Speed networking',
    category: 'NETWORKING',
    startDate: '2024-05-30T12:00:00',
    status: 'APPROVED',
    duration: 15,
    slotsLeft: 7,
    hostImageUrl: 'https://s3.eu-west-3.amazonaws.com/saleshookup-prod/660acd3e67263b02fc8f3797/avatar/Favicon_Saleshookup.png',
    description: '*This group hookup does not have a host.**\n\nA quick 15-minute group hookup to make introductions and efficiently grow your networks!',
    roomResponse: {
      webhook: null,
      autoCloseConfig: {
        duration: 15,
        type: 'session-ends'
      },
      disabled: false,
      createdAt: '2024-05-14T08:21:28.288Z',
      updatedAt: '2024-05-14T08:21:28.288Z',
      roomId: 'wiil-6l16-ock7',
      customRoomId: 'Speed networking',
      links: {
        get_room: 'https://api.videosdk.live/v2/rooms/wiil-6l16-ock7',
        get_session: 'https://api.videosdk.live/v2/sessions/'
      },
      id: '66431f08a42df057c070cdca'
    },
    participants: ['bc7b8589-10a9-438d-ac4e-08afcd9744b7'],
    cancellationReason: null,
    highlighted: false
  },
  {
    uuid: 'f3035b55-4332-4a05-8a1b-94c429f2ce06',
    hostFullName: 'Sales Hookup',
    hostUUID: 'bc7b8589-10a9-438d-ac4e-08afcd9744b7',
    title: 'Speed networking',
    category: 'NETWORKING',
    startDate: '2024-05-23T12:00:00',
    status: 'APPROVED',
    duration: 15,
    slotsLeft: 7,
    hostImageUrl: 'https://s3.eu-west-3.amazonaws.com/saleshookup-prod/660acd3e67263b02fc8f3797/avatar/Favicon_Saleshookup.png',
    description: '*This group hookup does not have a host.**\n\nA quick 15-minute group hookup to make introductions and efficiently grow your networks!',
    roomResponse: {
      webhook: null,
      autoCloseConfig: {
        duration: 15,
        type: 'session-ends'
      },
      disabled: false,
      createdAt: '2024-05-14T08:21:28.288Z',
      updatedAt: '2024-05-14T08:21:28.288Z',
      roomId: 'wiil-6l16-ock7',
      customRoomId: 'Speed networking',
      links: {
        get_room: 'https://api.videosdk.live/v2/rooms/wiil-6l16-ock7',
        get_session: 'https://api.videosdk.live/v2/sessions/'
      },
      id: '66431f08a42df057c070cdca'
    },
    participants: ['bc7b8589-10a9-438d-ac4e-08afcd9744b7'],
    cancellationReason: null,
    highlighted: false
  },
  {
    uuid: '4959ddb0-e7f7-4d83-9410-f306951d149a',
    hostFullName: 'Sales Hookup',
    hostUUID: 'bc7b8589-10a9-438d-ac4e-08afcd9744b7',
    title: 'Speed networking',
    category: 'NETWORKING',
    startDate: '2024-05-16T12:00:00',
    status: 'APPROVED',
    duration: 15,
    slotsLeft: 7,
    hostImageUrl: 'https://s3.eu-west-3.amazonaws.com/saleshookup-prod/660acd3e67263b02fc8f3797/avatar/Favicon_Saleshookup.png',
    description: '*This group hookup does not have a host.**\n\nA quick 15-minute group hookup to make introductions and efficiently grow your networks!',
    roomResponse: {
      webhook: null,
      autoCloseConfig: {
        duration: 15,
        type: 'session-ends'
      },
      disabled: false,
      createdAt: '2024-05-14T08:21:28.288Z',
      updatedAt: '2024-05-14T08:21:28.288Z',
      roomId: 'wiil-6l16-ock7',
      customRoomId: 'Speed networking',
      links: {
        get_room: 'https://api.videosdk.live/v2/rooms/wiil-6l16-ock7',
        get_session: 'https://api.videosdk.live/v2/sessions/'
      },
      id: '66431f08a42df057c070cdca'
    },
    participants: ['bc7b8589-10a9-438d-ac4e-08afcd9744b7'],
    cancellationReason: null,
    highlighted: true
  },
  {
    uuid: '8db9969e-54e2-479c-96c8-dbd1b4826e30',
    hostFullName: 'Sales Hookup',
    hostUUID: 'bc7b8589-10a9-438d-ac4e-08afcd9744b7',
    title: 'Tips on landing a great sales job',
    category: 'BRAINSTORMING',
    startDate: '2024-05-23T16:00:00',
    status: 'APPROVED',
    duration: 30,
    slotsLeft: 6,
    hostImageUrl: 'https://s3.eu-west-3.amazonaws.com/saleshookup-prod/660acd3e67263b02fc8f3797/avatar/Favicon_Saleshookup.png',
    description:
      "*This group hookup does not have a host.**\n\nThis 30-minute group hookup is for members to share what has worked for them when trying to land a top sales job. It's also a great chance for those looking for their next role to learn what worked from others.",
    roomResponse: {
      webhook: null,
      autoCloseConfig: {
        duration: 30,
        type: 'session-ends'
      },
      disabled: false,
      createdAt: '2024-05-14T08:19:22.680Z',
      updatedAt: '2024-05-14T08:19:22.680Z',
      roomId: 'q93u-7maw-9bvs',
      customRoomId: 'Tips on landing a great sales job',
      links: {
        get_room: 'https://api.videosdk.live/v2/rooms/q93u-7maw-9bvs',
        get_session: 'https://api.videosdk.live/v2/sessions/'
      },
      id: '66431e8ae3fc2441d6d894c6'
    },
    participants: ['bc7b8589-10a9-438d-ac4e-08afcd9744b7', '55647707-02c6-4a84-9aff-c747b862b207'],
    cancellationReason: null,
    highlighted: true
  },
  {
    uuid: '243df9f4-6a94-4c2d-b8c9-0b298ccff23a',
    hostFullName: 'Sales Hookup',
    hostUUID: 'bc7b8589-10a9-438d-ac4e-08afcd9744b7',
    title: "Do's and don'ts of cold calling",
    category: 'BRAINSTORMING',
    startDate: '2024-05-22T14:00:00',
    status: 'APPROVED',
    duration: 30,
    slotsLeft: 4,
    hostImageUrl: 'https://s3.eu-west-3.amazonaws.com/saleshookup-prod/660acd3e67263b02fc8f3797/avatar/Favicon_Saleshookup.png',
    description:
      "*This group hookup does not have a host.**\n\nUse this 30-minute group hookup to share things that worked for you when cold calling and things that didn't. Level up your cold-calling skills together!",
    roomResponse: {
      webhook: null,
      autoCloseConfig: {
        duration: 30,
        type: 'session-ends'
      },
      disabled: false,
      createdAt: '2024-05-14T08:15:49.989Z',
      updatedAt: '2024-05-14T08:15:49.989Z',
      roomId: 'cl3s-73ae-75mr',
      customRoomId: "Do's and don'ts of cold calling",
      links: {
        get_room: 'https://api.videosdk.live/v2/rooms/cl3s-73ae-75mr',
        get_session: 'https://api.videosdk.live/v2/sessions/'
      },
      id: '66431db5a42df057c070588a'
    },
    participants: [
      'bc7b8589-10a9-438d-ac4e-08afcd9744b7',
      '2b164bed-93e7-4251-846b-730fb0af4517',
      'e410a431-67fe-4c9e-a798-9667fbf6e993',
      'fee8a187-3088-42e5-a2e7-2f1f0e10ef3e'
    ],
    cancellationReason: null,
    highlighted: true
  },
  {
    uuid: 'c282841f-06b7-41ca-a62d-ecb3dcf7dc4d',
    hostFullName: 'Dominic Yates',
    hostUUID: 'e5f0603b-4142-4f03-b9cd-3e7a4c5b5605',
    title: '15-minute SalesHookup onboarding',
    category: 'NETWORKING',
    startDate: '2024-05-29T15:00:00',
    status: 'APPROVED',
    duration: 15,
    slotsLeft: 7,
    hostImageUrl: 'https://s3.eu-west-3.amazonaws.com/saleshookup-prod/641b2997ec714c7331b91e64/avatar/Dominic.jpeg',
    description:
      "If you're new to SalesHookup, you can use this 15-minute group onboarding session to ask me any questions/doubts you have and network with other members too.",
    roomResponse: {
      webhook: null,
      autoCloseConfig: {
        duration: 15,
        type: 'session-ends'
      },
      disabled: false,
      createdAt: '2024-05-14T08:08:52.638Z',
      updatedAt: '2024-05-14T08:08:52.638Z',
      roomId: '878n-oilt-1zu3',
      customRoomId: '15-minute SalesHookup onboarding',
      links: {
        get_room: 'https://api.videosdk.live/v2/rooms/878n-oilt-1zu3',
        get_session: 'https://api.videosdk.live/v2/sessions/'
      },
      id: '66431c14a42df057c06fc43f'
    },
    participants: ['e5f0603b-4142-4f03-b9cd-3e7a4c5b5605'],
    cancellationReason: null,
    highlighted: false
  },
  {
    uuid: 'da43df75-4a13-42ee-8980-cbb47e413fad',
    hostFullName: 'Dominic Yates',
    hostUUID: 'e5f0603b-4142-4f03-b9cd-3e7a4c5b5605',
    title: '15-minute SalesHookup onboarding',
    category: 'NETWORKING',
    startDate: '2024-05-22T15:00:00',
    status: 'APPROVED',
    duration: 15,
    slotsLeft: 5,
    hostImageUrl: 'https://s3.eu-west-3.amazonaws.com/saleshookup-prod/641b2997ec714c7331b91e64/avatar/Dominic.jpeg',
    description:
      "If you're new to SalesHookup, you can use this 15-minute group onboarding session to ask me any questions/doubts you have and network with other members too.",
    roomResponse: {
      webhook: null,
      autoCloseConfig: {
        duration: 15,
        type: 'session-ends'
      },
      disabled: false,
      createdAt: '2024-05-14T08:08:52.638Z',
      updatedAt: '2024-05-14T08:08:52.638Z',
      roomId: '878n-oilt-1zu3',
      customRoomId: '15-minute SalesHookup onboarding',
      links: {
        get_room: 'https://api.videosdk.live/v2/rooms/878n-oilt-1zu3',
        get_session: 'https://api.videosdk.live/v2/sessions/'
      },
      id: '66431c14a42df057c06fc43f'
    },
    participants: ['e5f0603b-4142-4f03-b9cd-3e7a4c5b5605', 'fcf751df-c6e6-4508-9b76-9ba1b58c1fee', 'e133fe7f-aa4d-461d-9033-a8a8947aa19d'],
    cancellationReason: null,
    highlighted: false
  }
];
